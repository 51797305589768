import AttendancesService from "./attendances";
import AuthService from "./auth";
import ClassroomsService from "./classrooms";
import CourseService from "./courses";
import EmployeesService from "./employees";
import EventsService from "./events";
import GradesService from "./grades";
import LessonService from "./lessons";
import ParentsService from "./parents";
import PermissionsService from "./permissions";
import RolesService from "./roles";
import SchoolProgramsService from "./school-programs";
import SemesterService from "./semesters";
import StudentsService from "./students";
import SubjectsService from "./subjects";
import TeachersService from "./teachers";
import TimetableService from "./timtable";
import AssignmentsService from "./assigments";
import PublicNotificationService from "./public-notification";
import SettingService from "./setting";
import CategoyService from "./category";
import AssessmentsService from "./assessments";

export default class EndPoints {
  public static auth = new AuthService();
  public static student = new StudentsService();
  public static role = new RolesService();
  public static permission = new PermissionsService();
  public static employee = new EmployeesService();
  public static parent = new ParentsService();
  public static teacher = new TeachersService();
  public static grade = new GradesService();
  public static subject = new SubjectsService();
  public static classroom = new ClassroomsService();
  public static course = new CourseService();
  public static lesson = new LessonService();
  public static attendance = new AttendancesService();
  public static semester = new SemesterService();
  public static timetable = new TimetableService();
  public static schoolProgram = new SchoolProgramsService();
  public static event = new EventsService();
  public static assignment = new AssignmentsService();
  public static publicNotification = new PublicNotificationService();
  public static setting = new SettingService();
  public static category = new CategoyService();
  public static assessments = new AssessmentsService()
}
