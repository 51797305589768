import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ILessonQuery } from "../../models/lessons/query";
import { ICreateLesson, IUpdateLesson } from "../../models/lessons/request";
import { IAssignmentQuery } from "../../models/assignments/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IAssignment } from "../../models/assignments/response";
import { ICreateAssignment, IUpdateAssignment } from "../../models/assignments/request";

class AssignmentsService {
  getAllAssignments = (
    query: IAssignmentQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/assignments", { params: query });

  getAllAssignmentssLight = (query: IAssignmentQuery): Promise<IAssignment> =>
    http.get("/assignments", {
      params: {
        light: 1,
      },
    });

  getProgress = (query: IAssignmentQuery): Promise<any> =>
    http.get("/assignments-progress", { params: query });

  getAssignment = (id: number): Promise<any> => http.get(`/assignments/${id}`);

  createAssignment = (data: ICreateAssignment): Promise<any> =>
    http.post("/assignments", data);

  updateAssignment = (data: IUpdateAssignment, id: number): Promise<IAssignment> =>
    http.put(`/assignments/${id}`, data);

  deleteAssignment = (id: number): Promise<IAssignment> => http.delete(`/assignments/${id}`);
  submitAssignment = (data: any, id: number): Promise<any> =>
    http.post(`/assignments/submit/${id}`, data);
}

export default AssignmentsService;
