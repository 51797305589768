import { DATE_FORMATE } from "./constants";
import { floor, isArray, xor } from "lodash";

export const toggleLoading = (loading: any[], toggle: any[] | any) => {
  return xor(loading, isArray(toggle) ? toggle : [toggle]);
};

export const a2e = (s: string) =>
  s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString());

export const getpage = (page: number, pageSize: number) => {
  return page / pageSize;
};
export const nextPage = (page: number, pageSize: number) => {
  return page * pageSize;
};

export const reactionsLogic = (reactions: any) => {
  const groupedReactions = reactions.reduce((acc: any, current: any) => {
    if (!acc[current.reaction]) {
      acc[current.reaction] = [];
    }

    if (
      !acc[current.reaction].some(
        (reaction: any) => reaction.reactorId === current.reactorId
      )
    ) {
      acc[current.reaction].push(current);
    }

    return acc;
  }, {});
  return groupedReactions;
};
