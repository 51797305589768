import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ILessonQuery } from "../../models/lessons/query";
import { ICreateLesson, IUpdateLesson } from "../../models/lessons/request";
import { IAssignmentQuery } from "../../models/assignments/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IAssignment } from "../../models/assignments/response";
import { ICreateAssignment, IUpdateAssignment } from "../../models/assignments/request";
import { IPublicNotificationQuery } from "../../models/public-notification/query";
import { ICreatePublicNotification, IUpdatePublicNotification } from "../../models/public-notification/request";
import { IPublicNotification } from "../../models/public-notification/response";

class PublicNotificationService {
  getAllPublicNotifications = (
    query: IPublicNotificationQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/public-notifications", { params: query });

  getAllPublicNotificationLight = ( query: IPublicNotificationQuery): Promise<IPublicNotification> =>
    http.get("/public-notifications", {
      params: {
        light: 1,
      },
    });

  getpublicNotification = (id: number): Promise<any> => http.get(`/public-notifications/${id}`);

  sendPublicNotification = (id: number): Promise<any> => http.post(`/public-notifications/send/${id}`);

  createPublicNotification = (data: ICreatePublicNotification): Promise<any> =>
    http.post("/public-notifications", data);

  updatePublicNotification = (data: IUpdatePublicNotification, id: number): Promise<IPublicNotificationQuery> =>
    http.put(`/public-notifications/${id}`, data);

  deletePublicNotification = (id: number): Promise<IAssignment> => http.delete(`/public-notifications/${id}`);
}

export default PublicNotificationService;
