import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ILessonQuery } from "../../models/lessons/query";
import { ICreateLesson, IUpdateLesson } from "../../models/lessons/request";

class LessonService {
  getAllLessons = (
    query: ILessonQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/lessons", { params: query });

  getAllLessonsLight = (params?: any): Promise<any> =>
    http.get("/lessons", {
      params: {
        light: 1,
        ...params
      },
    });

  getLesson = (id: number): Promise<any> => http.get(`/lessons/${id}`);

  createLesson = (data: ICreateLesson): Promise<any> =>
    http.post("/lessons", data);

  updateLesson = (data: IUpdateLesson, id: number): Promise<any> =>
    http.put(`/lessons/${id}`, data);

  deleteLesson = (id: number): Promise<any> => http.delete(`/lessons/${id}`);
}

export default LessonService;
