import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";

class SettingService {
  getSetting = (): Promise<any> => http.get(`/settings`);

  updateSetting = (data: any): Promise<any> => http.put("/settings", data);
}

export default SettingService;
