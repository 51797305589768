import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IClassRoomQuery } from "../../models/classrooms/query";
import {
  ICreateClassRoom,
  IUpdateClassRoom,
} from "../../models/classrooms/request";
import { ICategoryQuery } from "../../models/category/query";
import { ICreateCategory, IUpdateCategory } from "../../models/category/request";
import { IAssessmentsQuery } from "../../models/assessment/query";
import { ICreateAssessments } from "../../models/assessment/request";

class AssessmentsService {
  getAllAssessments = (
    query: IAssessmentsQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/assessments", { params: query });

  getAllAssessmentsLight = (params?: any): Promise<any> =>
    http.get("/assessments", {
      params: {
        light: 1,
        ...params,
      },
    });

  getAssessment = (id: number): Promise<any> => http.get(`/assessments/${id}`);

  createAssessment = (data: ICreateAssessments): Promise<any> =>
    http.post("/assessments", data);

  updateAssessment = (data: IUpdateCategory, id: number): Promise<any> =>
    http.put(`/assessments/${id}`, data);

  deleteAssessment = (id: number): Promise<any> =>
    http.delete(`/assessments/${id}`);
}

export default AssessmentsService;
