import axios, { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ILessonQuery } from "../../models/lessons/query";
import { ICreateLesson, IUpdateLesson } from "../../models/lessons/request";
import { promises } from "dns";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";

class AttendancesService {
  getAllAttendances = (query: any, params?: AxiosRequestConfig): Promise<any> =>
    http.get("/attendances", { params: query });

  getAllAttendacesLight = (): Promise<any> =>
    http.get("/attendances", {
      params: {
        light: 1,
      },
    });

  getAttendacePerDay = (data?: any, id?: number): Promise<any> =>
    http.post(`/attendances/student/${id}`, data);

  showReport = (data: any): Promise<any> =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/reports/attendance`,
      data,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          "Content-Type": "application/json",
        },
        params: { },
        responseType: "arraybuffer",
      }
    );

  getAttendance = (id: number): Promise<any> => http.get(`/attendances/${id}`);

  getStudentsAttendanceAbsenceJs = (data: any): Promise<any> =>
    http.get("/students-justified-absence", { params: data });

  getStudentsAttendanceAbsenceUnjs = (data: any): Promise<any> =>
    http.get(`/students-unjustified-absence`, { params: data });

  getStudentsAttendancTardy = (data: any): Promise<any> =>
    http.get(`/students-tardy`, { params: data });

  getStudentsAttendanceByLesson = (data: any): Promise<any> =>
    http.get(`students-attendance-by-lesson`, { params: data });

  createAttendace = (data: any): Promise<any> =>
    http.post("/attendances", data);

  updateAttendance = (data: any, id: number): Promise<any> =>
    http.put(`/attendances/${id}`, data);

  deleteAttendace = (id: number): Promise<any> =>
    http.delete(`/attendances/${id}`);
}

export default AttendancesService;
