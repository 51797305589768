import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { ICreateEvent, IUpdateEvent } from "../../models/events/request";

class EventsService {
  getAllEvents = (query: any, params?: AxiosRequestConfig): Promise<any> =>
    http.get("/calendars", { params: query });

  getAllEventsLight = (params?: any): Promise<any> =>
    http.get("/calendars", {
      params: {
        light: 1,
        ...params,
      },
    });

  getEvent = (id: number): Promise<any> => http.get(`/calendars/${id}`);

  createEvent = (data: ICreateEvent): Promise<any> =>
    http.post("/calendars", data);

  updateEvent = (data: IUpdateEvent, id: number): Promise<any> =>
    http.put(`/calendars/${id}`, data);

  deleteEvent = (id: number): Promise<any> => http.delete(`/calendars/${id}`);
}

export default EventsService;
