import { AxiosRequestConfig } from "axios";
import http from "../../api/axios";
import { IClassRoomQuery } from "../../models/classrooms/query";
import {
  ICreateClassRoom,
  IUpdateClassRoom,
} from "../../models/classrooms/request";
import { ICategoryQuery } from "../../models/category/query";
import { ICreateCategory, IUpdateCategory } from "../../models/category/request";

class CategoyService {
  getAllcategories = (
    query: ICategoryQuery,
    params?: AxiosRequestConfig
  ): Promise<any> => http.get("/categories", { params: query });

  getAllCategoriesLight = (params?: any): Promise<any> =>
    http.get("/categories", {
      params: {
        light: 1,
        ...params,
      },
    });

  getCategory = (id: number): Promise<any> => http.get(`/categories/${id}`);

  createCategory = (data: ICreateCategory): Promise<any> =>
    http.post("/categories", data);

  updateCategory = (data: IUpdateCategory, id: number): Promise<any> =>
    http.put(`/categories/${id}`, data);

  deleteCategory = (id: number): Promise<any> =>
    http.delete(`/categories/${id}`);
}

export default CategoyService;
