import { createContext } from "react";
// import { ILoginRequest } from '../../models/auth/request'
import { ACCESS_TOKEN, DEFAULT_FUNCTION } from "../../utils/helpers/constants";
// import Permission from '../../utils/rbac/permissions'
import { IUserLogin } from "../../models/user-login/response";
import { ILogin } from "../../models/user-login/request";

export type AuthLoading =
  | "login"
  | "logout"
  | "change_password"
  | "forget_password"
  | "roles"
  | "update_profile";

export interface IInternalState {
  loading: AuthLoading[];

  isAuthenticated?: boolean;
  authUser?: IUserLogin;
  userPermissions: any[];
}

export const internalState: IInternalState = {
  loading: [],
  isAuthenticated: !localStorage.getItem(ACCESS_TOKEN) ? false : true,
  userPermissions: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: ILogin) => void;
    logout: () => void;
    changePassword: (data: any) => void;
    updateProfile: (data: any) => void;
    forgetPassword: (email: string) => void;
    setStudentSelect: (studentId : any) => void
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
    updateProfile: DEFAULT_FUNCTION,
    forgetPassword: DEFAULT_FUNCTION,
    setStudentSelect : DEFAULT_FUNCTION
  },
};

const AuthContext = createContext(externalState);

export default AuthContext;
